@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "InterRegular", "MontserratRegular", "OpenSansRegular",
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif, "";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter/Inter_18pt-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "InterBold";
  src: url("./assets/fonts/Inter/Inter_18pt-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MontserratBold";
  src: url("./assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans";
  src: url("./assets/fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MontserratBold";
  src: url("./assets/fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
